<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on }">
      <v-btn style="margin: 0" color="secondary" v-on="on" block> {{ $t('Regístrate vía email') }}</v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t('Regístrate vía email') }} </span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.nombre" :label="$t('Nombre')" :rules="name_rules" required />
            </v-col>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.apellidos" :label="$t('Apellidos')" :rules="name_rules" required="" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.email" :label="$t('Email')" :rules="email_rules" required />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.password" :label="$t('Password')" :rules="password_rules" type="password" required />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.telefono" :label="$t('Teléfono')" :rules="phone_rules" required/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-select
                  v-model="user.ciudad"
                  :items="cities_items"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('Ciudad')"
                  :rules="ciudad_rules"
                  required
              />
            </v-col>

            <v-col class="pt-0 pb-0">
              <v-select
                  v-model="user.sexo"
                  :items="sexos_items"
                  item-text="text"
                  item-value="value"
                  :label="$t('Sexo')"
                  :rules="sexo_rules"
                  required
              />
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" text @click="register">{{ $t('Registrarse') }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: 'Registro',
  data(){
    return {
      dialog: false,
      valid: false,
      user: {
        nombre: "",
        apellidos: "",
        email: "",
        password: "",
        telefono: "",
        ciudad: null,
        sexo: null,
        origen: 'w',
        locale: this.$i18n.locale
      },

      cities_items: [],
      sexos_items: [
        { value: 'f', text: this.$t('Mujer') },
        { value: 'm', text: this.$t('Hombre') }
      ],

      name_rules: [
        v => !!v || this.$t('El nombre es obligatorio'),
        v => v.length >= 3 || this.$t('El nombre debe tener al menos 3 caracteres')
      ],
      email_rules: [
        v => !!v || this.$t('El email es obligatorio'),
        v => /.+@.+/.test(v) || this.$t('El email debe ser válido')
      ],
      password_rules: [
        v => !!v || this.$t('El password es obligatorio'),
        v => (v && v.length >= 6) || this.$t('El password debe tener al menos 6 caracteres')
      ],
      phone_rules: [
        phone => !!phone || this.$t('El teléfono es obligatorio'),
        phone => phone.length >= 9 || this.$t('El número de móvil debe tener al menos 9 dígitos'),
        phone => !isNaN(phone) || this.$t('El número de móvil debe tener solo cifras')
      ],
      ciudad_rules: [
        v => !!v || this.$t('La ciudad es obligatoria')
      ],
      sexo_rules: [
        v => !!v || this.$t('El sexo es obligatorio')
      ]
    }
  },

  methods: {
    async register() {
      if(this.$refs.form.validate()){
        const user = await this.$store.dispatch('auth/register', this.user)
        if(user){
          this.$notify({
            title: this.$t('Acción realizada correctamente'),
            text: this.$t(
                'Te hemos enviado un email a {email} y un SMS al número {telefono} para que puedas activar tu cuenta. Revisa tu carpeta de correo no deseado.',
                { email: user.email, telefono: user.telefono }
            )
          })
          this.dialog = false
          this.$emit('on_registro')
        }
      }
    },

    async getCities () {
      try {
        this.cities_items = await this.$store.dispatch('common/get_cities', {
          url: window.location.hostname
        })
      } catch (e) {
        this.$notify({
          title: this.$t('Error'),
          text: this.$t('Se ha producido un error.'),
          style: 'error'
        })
      }
    }
  },

  mounted() {
    this.getCities()
    if(this.centro){
      this.user.ciudad = this.centro.city.id
    }
  },

  computed: {
    centro(){
      return this.$store.state.centro.centro
    }
  }
}
</script>
